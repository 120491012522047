import { LoyaltyService } from './services/loyalty.service';
import { SaleService } from './services/sale.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';

// Directives
import { NavigationMenuComponent } from './navigationMenu/navigationMenu.directive';

// Services
import { BaseHttp, AuthService, LocationService, CanActivateService, ShopifyService } from './services';
import { Store } from './store';

// Translation
import { TranslateService, TRANSLATION_PROVIDERS } from './translations';


@NgModule({
    imports: [RouterModule, SharedModule, HttpClientModule],
    declarations: [NavigationMenuComponent],
    providers: [
        CanActivateService,
        Store,
        BaseHttp,
        AuthService,
        LocationService,
        SaleService,
        ShopifyService,
        LoyaltyService,
        TranslateService,
        TRANSLATION_PROVIDERS
    ],
    exports: [NavigationMenuComponent],
})
export class CoreModule {

}

