import { Injectable } from '@angular/core';
import { BaseHttp } from './baseHttp.service';
import { Observable } from 'rxjs';
import { DashboardsConfigResponse, DashboardMeasure } from '../../../model';
import * as _ from 'underscore';
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardService {
    constructor(private http: BaseHttp) {
    }

    public getDashboardConfig(): Observable<DashboardsConfigResponse> {
        return this.http.get('/DashboardsConfig')
                   .pipe(map((response: any) => response as DashboardsConfigResponse));
    }

    public putDashboardConfig(measures: DashboardMeasure[]): Observable<DashboardsConfigResponse> {
        return this.http.put('/DashboardsConfig', { Measures: measures })
                   .pipe(map((response: any) => response as DashboardsConfigResponse));
    }
}
