import { EmployeesListComponent } from './../../employees/employees-list.component';
export const LANG_EN_TRANS = {
    // menu
    'menu.dashboard': 'Dashboard',
    'menu.reports': 'Reports',
    'menu.loyalty-cards': 'Loyalty',
    'menu.logout': 'Logout',
    'menu.employees': 'Employees',
    'menu.admin': 'Admin',

    'admin.general_group_title': 'Admin',
    'admin.list': 'Venue List',
    'admin.apikeylist': 'Api Key List',

    // calendar
    'calendar_hour': 'Hour',
    'calendar_day': 'Day',
    'calendar_week': 'Week',
    'calendar_month': 'Month',
    'calendar_year': 'Year',

    // reportViewType
    'reportViewType_summary': 'Summary',
    'reportViewType_detail': 'Detail',

    // reports
    'report_subtitle': 'Sales',
    'report_title': 'Sales Summary',
    'reports.sales_group_title': 'Sales',
    'reports.sales_item_1': 'Sales Summary',
    'reports.loyalty_points': 'Loyalty Points',
    'reports.sales_breakdown': 'Sales Breakdown',
    'reports.time_attendance': 'T & A',
    'reports.invoices_group_title': 'Invoices',
    'reports.invoices_item_1': 'List invoices',
    'reports.export_to_csv': 'Export',

    // employees
    'employees.general_group_title': 'Membership',
    'employees.accesscontrol_group_title': 'Actions',
    'employees.list': 'List',
    'employees.new': 'Create',
    'employees.register': 'Enable Access',
    'employees.revoke': 'Revoke Access',
    'employees.permissions': 'Permissions',
    'employees.requestpassword': 'Request Password',
    'employees.impersonate': 'Impersonate',
    'employees.addtovenue': 'Add to Venue',
    'employees.deletefromvenue': 'Delete from Venue',
    'Employee': 'Employee',
    'Locations': 'Locations',
    'Location': 'Location',
    'First Name' : 'First Name',
    'User Name' : 'Username',
    'Display Name' : 'Display Name',
    'Password' : 'Password',
    'NickName' : 'Nickname',
    'Surname' : 'Surname',
    'Name': 'Name',
    'Email' : 'Email',
    'Active' : 'Active',
    'Registered' : 'Registered',
    'Actions' : 'Actions',
    'Enable' : 'Enable',
    'Revoke' : 'Revoke',
    'Request' : 'Request',

    // Loyalty
    'card.details': 'Details',
    'card.usage': 'Usage',
    'card.adjust': 'Adjust',
    'card.transfer': 'Transfer',
    'card.replace': 'Replace',

    // lists
    'total': 'Total',
    'region_title': 'Summary per Region',
    'region_store': 'Summary per Store',
    'points_per_store': 'Points per Store',
    'column_loyalty_points': 'Loyalty Points',
    'column_adjustment': 'Adjustment',
    'column_voucher_sales': 'Voucher Sales',
    'column_sales_reports': 'Sales Reports',
    'column_member_card': 'Member Card',
    'column_total_points': 'Total Points',
    'column_region': 'Region',
    'column_store': 'Store',
    'column_date': 'Date',
    'column_dinein': 'Dinein',
    'column_deliveries': 'Deliveries',
    'column_takeaways': 'Takeaways',
    'column_totals': 'Totals',
    'column_loyalty': 'Loyalty',
    'column_sales': 'Sales',
    'column_percust': '$/Cust',
    'column_qty': 'Qty',
    'column_customers': 'Customers',
    'column_amount': 'Amount',
    'column_shift': 'Shift',
    'column_item': 'Product',
    'column_bd_count': 'Count',
    'column_bd_value': 'Sales$',
    'column_bd_avg': 'Avg Price',
    'column_bd_perc': '% / Amount',
    'column_name': 'Name',
    'column_period': 'Period',
    'column_points_accrued': '+ (D)',
    'column_points_redeemed': '- (C)',
    'column_adjustment_accrued': '+ (F)',
    'column_adjustment_redeemed': '- (E)',
    'column_twenty_dollar': '$20',
    'column_twenty_five_dollar': '$25',
    'column_fifty_dollar': '$50',
    'column_one_hundred_dollar': '$100',
    'column_total_voucher_sales': 'Total Points (K)',
    'column_sales_reports_point': 'Point',
    'column_sales_reports_cnt': 'Cnt',
    'column_member_card_point': 'Point',
    'column_member_card_cnt': 'Cnt',
    'column_total_points_ce': '(C+E)',
    'column_total_points_dfk': '(D+F+K)',
    'column_monday': 'Monday',
    'column_tuesday': 'Tuesday',
    'column_wednesday': 'Wednesday',
    'column_thursday': 'Thursday',
    'column_friday': 'Friday',
    'column_saturday': 'Saturday',
    'column_sunday': 'Sunday',
    'column_employee': 'Employee',
    'column_roster': 'Roster',
    'column_claimed': 'Claimed',
    'column_actual': 'Actual',
    'column_hours': 'Hours',

    // metric options
    'Sales Amount' : 'Sales Amount',
    'Takeaways' : 'Takeaways',
    'Deliveries' : 'Deliveries',
    'Totals' : 'Totals',

    // csv
    'id': 'Id',
    'name': 'Name',
    'date': 'Date',
    'deliveryCount': 'Delivery Count',
    'deliveryLoyalty': 'Delivery Loyalty',
    'deliverySales': 'Delivery Sales',
    'takeawayCount': 'Takeaway Count',
    'takeawayLoyalty': 'Takeaway Loyalty',
    'takeawaySales': 'Takeaway Sales',
    'totalCount': 'Total Count',
    'totalCustomers': 'Total Customers',
    'totalLoyalty': 'Total Loyalty',
    'totalsales': 'Total Sales',

    // alertify messages
    'formSaved' : 'The changes have been saved.',

    // general
    'Please select...': 'Please select...',
    'Select items': 'Select items',
    'Select Locations': 'Select Locations',
    'Select Locations...': 'Select Locations...',
    'Select Employee': 'Select Employee',
    'Select Permissions...': 'Select Permissions...',
    'Select Permissions': 'Select Permissions',
    'Select Employee...': 'Select Employee...',
    'Select metrics...': 'Select metrics...',
    'Select metrics': 'Select metrics',

    // loyalty
    'total cannot be less than zero': 'total cannot be less than zero',
    'Search Loyalty Card': 'Search Loyalty Card',
    'Adjust Points': 'Adjust Points',
    'Transfer Points': 'Transfer Points',
    'Fraud Check': 'Fraud Check',
    'Usage summary': 'Usage summary',
    'Management': 'Management',
    'Reports': 'Reports',
    'Period': 'Period',
    'Threshold': 'Threshold',
    'Date': 'Date',
    'Card Number': 'Card Number',
    'Count Used': 'Count Used',
    'From Card Number': 'From Card Number',
    'To Card Number': 'To Card Number',
    'Filter by': 'Filter By',
    'Loyalty Count': 'Loyalty Count',
    'Accrued': 'Accrued',
    'Redeemed': 'Redeemed',
    'Voucher Amount': 'Voucher Amount',
    'Voucher Count': 'Voucher Count',
    'loyalty.replace': 'Replace Card',

    // config
    'Save': 'Save',
};
