import { employee, location, employeeCreate, UserToken, permission, IUser, ImpersonateResponse, UserVenue } from './../../../model/index';
import { Store } from '../store';
import { Observable } from 'rxjs';
import { BaseHttp } from './baseHttp.service';
import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { map } from 'rxjs/operators';

@Injectable()
export class EmployeeService {
    constructor(private http: BaseHttp, private store: Store) {
    }

    public get(): Observable<employee[]> {
        return this.http.get('/employees')
                   .pipe(map((response: any) => response.Employees as employee[]));
    }

    public getByVenue(venueId: string): Observable<employee[]> {
        return this.http.get(`/venue/${venueId}/employees`)
            .pipe(map((response: any) => response.Employees as employee[]));
    }

    public getByVenueAndId(venueId: string, id: number): Observable<employee[]> {
        return this.http.get(`/venue/${venueId}/employees/${id}`)
                   .pipe(map((response: any) => response.Employees as employee[]));
    }

    public getByToken(token: string): Observable<UserToken> {
        return this.http.get(`/employees/${token}/name`)
                   .pipe(map((response: any) => response as UserToken));
    }

    public getPermissions(employeeId: number): Observable<permission[]> {
        return this.http.get(`/employees/${employeeId}/permissions`)
                   .pipe(map((response: any) => response.Permissions as permission[]));
    }

    public setPermissions(employeeId: number, permissions: permission[]) {
        return this.http.post(`/employees/${employeeId}/permissions`, {
            EmployeeId: employeeId,
            Permissions: permissions
        });
    }

    public getPermissionsByVenue(venueId: string, employeeId: number): Observable<permission[]> {
        return this.http.get(`/venue/${venueId}/employees/${employeeId}/permissions`)
                   .pipe(map((response: any) => response.Permissions as permission[]));
    }

    public setPermissionsByVenue(venueId: string, employeeId: number, permissions: permission[], role: string) {
        return this.http.post(`/venue/${venueId}/employees/${employeeId}/permissions`, {
            EmployeeId: employeeId,
            Permissions: permissions,
            Roles: role
        });
    }

    public create(emp: employeeCreate) {
        return this.http.post(`/register`, emp)
                   .pipe(map((response: any) => response));
    }

    public register(emp: employee, locations: location[], permissions: permission[], passwordUrl: string) {

        return this.http.post(`/employees/${emp.EmployeeId}/register`, {
            PasswordURL: passwordUrl,
            Locations: locations,
            Permissions: permissions
        })
            .pipe(map((response: any) => response));
    }

    public registerByVenue(venueId: string, emp: employee, locations: location[], permissions: permission[], role: string, passwordUrl: string) {

        return this.http.post(`/venue/${venueId}/employees/${emp.EmployeeId}/register`, {
            PasswordURL: passwordUrl,
            Locations: locations,
            Permissions: permissions,
            Roles: role
        })
            .pipe(map((response: any) => response));
    }

    public setPassword(token: string, userName: string, password: string) {
        return this.http.post(`/employees/${token}/password`, {
            EmployeeToken: token,
            UserName: userName,
            Password: password
        })
            .pipe(map((response: any) => response));
    }

    public revoke(emp: employee) {
        return this.http.post(`/employees/${emp.EmployeeId}/revoke`, {
            EmployeeId: emp.EmployeeId
        })
            .pipe(map((response: any) => response));
    }

    public revokeByVenue(venueId: string, emp: employee) {
        return this.http.post(`/venue/${venueId}/employees/${emp.EmployeeId}/revoke`, {
            EmployeeId: emp.EmployeeId
        })
            .pipe(map((response: any) => response));
    }

    public requestPassword(emp: employee, passwordUrl: string) {

        return this.http.post(`/employees/${emp.EmployeeId}/requestpassword`, {
            EmployeeId: emp.EmployeeId,
            PasswordURL: passwordUrl
        })
            .pipe(map((response: any) => response));
    }

    public requestPasswordByEmployee(venudId: string, emp: employee, passwordUrl: string) {

        return this.http.post(`/venue/${venudId}/employees/${emp.EmployeeId}/requestpassword`, {
            EmployeeId: emp.EmployeeId,
            PasswordURL: passwordUrl
        })
            .pipe(map((response: any) => response));
    }

    public changePassword(token: string, password: string) {

        return this.http.post(`/employees/${token}/changepassword`, {
            EmployeeToken: token,
            Password: password
        })
            .pipe(map((response: any) => response));

    }

    adminUnlockEmployee(employeeId: number, venueId: string): Observable<any> {
        return this.http.post(`/venue/${venueId}/employees/${employeeId}/unlock`, {
            VenueId: venueId,
            EmployeeId: employeeId
        });
    }

    venueAdminUnlockEmployee(employeeId: number): Observable<any> {
        return this.http.post(`/employees/${employeeId}/unlock`, {
            EmployeeId: employeeId
        });
    }

    adminImpersonate(employeeId: number, venueId: string): Observable<IUser> {
        return this.http.post(`/venue/${venueId}/employees/${employeeId}/impersonate`, {
            VenueId: venueId,
            EmployeeId: employeeId
        })
        .pipe(map((response: any) => {
            const resp = response as ImpersonateResponse;
            const user = this.store.getUser();
            if (!!user) {
                user.Token = resp.Token;
                this.store.setUser(user);
            }
            return user;
        }));
    }

    getVenuesForEmployee(venueId: string, employeeId: number): Observable<UserVenue[]> {
        return this.http.get(`/venues/${venueId}/employees/${employeeId}/venues`)
                   .pipe(map((response: any) => response.Venues));
    }


    public setVenuesForEmployee(venueId: string, employeeId: number, venues: UserVenue[]) {
        return this.http.post(`/venues/${venueId}/employees/${employeeId}/assign`, {
            Venues: venues,
        });
    }

}
