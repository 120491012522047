import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../core/services/auth.service';

@Pipe({
    name: 'loyaltyname',
    pure: false
})
export class LoyaltyNamePipe implements PipeTransform {

    constructor(private authService: AuthService) { }

    transform(value: string, args: any[]): any {
        if (!value) {
            return;
        }
        if (value.toLocaleLowerCase() === 'loyalty') {
            const usr = this.authService.getUser();
            if (usr && usr.Config) {
                return usr.Config.LoyaltyName;
            }
        }
        return value;
    }
}
