import { Observable } from 'rxjs';
import { BaseHttp } from './baseHttp.service';
import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { map } from 'rxjs/operators';

@Injectable()
export class ShopifyService {
    constructor(private http: BaseHttp) {
    }

    getAuthenticateUrl(id: string, redirect: string): Observable<string> {
        return this.http.get(`/venue/${id}/shopifyauthurls?redirectUrl=${redirect}`)
                   .pipe(map((response: any) => response.AuthUrl as string));
    }

    getAuthenticateUrlForLocation(id: string, locId: string, redirect: string): Observable<string> {
        return this.http.get(`/venue/${id}/location/${locId}/shopifyauthurls?redirectUrl=${redirect}`)
                   .pipe(map((response: any) => response.AuthUrl as string));
    }

    getAuthenticatedUrlForShop(shop: string, redirect: string): Observable<string> {
        return this.http.get(`/shopify/shopifyauthurls?shopifyURL=${shop}&redirectUrl=${redirect}`)
                   .pipe(map((response: any) => response.AuthUrl as string));
    }
}

