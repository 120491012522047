import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '../translations/translate.service';
import { AuthService } from '../services/auth.service';
import * as _ from 'underscore';
import { Navigation } from '../../shared/constants/navigation';
import { Role } from 'app/shared/constants/role';
import { CAN_VIEWSALES, CAN_VIEW_TIMESHEET, CAN_VIEW_ROSTER, CAN_VIEW_DASHBOARD, CAN_LINK_PAYMENT } from 'app/shared/constants/permission';
import { IUser } from 'model';

declare var $: any;

@Component({
    selector: 'navigation-menu',
    templateUrl: `./navigationMenu.html`
})
export class NavigationMenuComponent implements AfterViewInit {

    language: string;
    navigation: TopNavItem[];
    subMenu: LinkGroup[];
    currentUser: IUser;

    constructor(
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService) {

        this.language = 'en';
        this.navigation = this.getNavigationMenu();

        this.router.events.subscribe((event: NavigationEnd) => {

            if (event instanceof NavigationEnd) {
                this.setAsActive(event.url);
            }

        });
    }

    hasPermissions(tags: string[]): boolean {
        if (!!tags) {
            return this.authService.hasPermission(tags);
        }

        return true;
    }

    topNavPermission(topNav: TopNavItem): boolean {
        let result = false;

        switch (topNav.name) {
            case Navigation.TOP_NAV_REPORTS: {
                const user = this.authService.getUser();
                if (user && user.Permissions) {
                    const difference = _.difference(_.map(user.Permissions, function (permission) {
                        return permission.Tag;
                    }), [CAN_VIEWSALES, CAN_VIEW_TIMESHEET, CAN_VIEW_ROSTER]);

                    if (difference.length !== user.Permissions.length) {
                        result = true;
                    }
                }
                break;
            }
            default: {
                result = this.hasPermissions(topNav.permissions);
                break;
            }
        }

        return result;
    }

    showBreakdown(): boolean {
        return this.authService.showBreakdownPage();
    }

    showControlSheet(): boolean {
        return this.authService.showControlSheet();
    }

    showTimeAndAttendance(): boolean {
        return this.authService.showTimeAndAttendance();
    }

    showExcises(): boolean {
        return this.authService.showExcises();
    }

    isAdmin(): boolean {
        return this.authService.hasRole('Admin');
    }

    ngAfterViewInit() {
        const $body = $('body'),
            $menu = $('#c-menu--slide-right'),
            $mask = $('#c-mask');

        $body
            .on('click',
                '#menu-btn',
                () => {
                    $body.addClass('has-active-menu');
                    $menu.addClass('is-active');
                    $mask.addClass('is-active');
                })
            .on('click',
                '#c-mask, .c-menu__close, .top-nav-item, .c-menu-item',
                () => {
                    $body.removeClass('has-active-menu');
                    $menu.removeClass('is-active');
                    $mask.removeClass('is-active');
                });
    }

    translate() {
        this.language = this.language === 'en' ? 'jp' : 'en';
        this.translateService.use(this.language);
    }

    getNavigationMenu(): TopNavItem[] {
        let result: TopNavItem[] = [];
        if (this.isAdmin()) {
            result = [
                {
                    icon: 'ion-ios-albums',
                    labelKey: 'menu.admin',
                    path: '/admin',
                    groups: [
                        {
                            labelKey: 'admin.general_group_title',
                            items: [
                                {
                                    labelKey: 'admin.list',
                                    path: '/admin/list',
                                },
                                {
                                    labelKey: 'admin.apikeylist',
                                    path: '/admin/apikeylist',
                                }
                            ]
                        }
                    ]
                },
                {
                    icon: 'ion-md-contacts',
                    labelKey: 'menu.employees',
                    path: '/admin/employees',
                    permissions: ['can_viewemp'],
                    groups: [
                        {
                            labelKey: 'employees.general_group_title',
                            items: [
                                {
                                    labelKey: 'employees.list',
                                    path: '/admin/employees/list',
                                },
                                {
                                    labelKey: 'employees.new',
                                    path: '/admin/employees/create',
                                }
                            ]
                        },
                        {
                            labelKey: 'employees.accesscontrol_group_title',
                            items: [
                                {
                                    labelKey: 'employees.register',
                                    path: '/admin/employees/register',
                                    permissions: ['can_register']
                                },
                                {
                                    labelKey: 'employees.revoke',
                                    path: '/admin/employees/revoke',
                                    permissions: ['can_revoke']
                                },
                                {
                                    labelKey: 'employees.requestpassword',
                                    path: '/admin/employees/requestpassword',
                                },
                                {
                                    labelKey: 'employees.permissions',
                                    path: '/admin/employees/permissions',
                                    permissions: ['can_register']
                                }
                            ]
                        }
                    ]
                },
                {
                    icon: 'ion-ios-log-out',
                    labelKey: 'menu.logout',
                    path: '/membership/logout'
                }
            ];
        } else {
            result = [
                {
                    icon: Navigation.ICON_ION_IOS_STATS,
                    labelKey: Navigation.LABELKEY_MENU_REPORTS,
                    path: Navigation.PATH_REPORTS,
                    name: Navigation.TOP_NAV_REPORTS,
                    groups: [
                        {
                            labelKey: Navigation.LABELKEY_REPORTS_SALES_GROUP_TITLE,
                            permissions: [CAN_VIEWSALES],
                            items: [
                                {
                                    labelKey: Navigation.LABELKEY_REPORTS_SALES_ITEM_1,
                                    path: Navigation.PATH_REPORTS_SALES
                                },
                                {
                                    labelKey: Navigation.LABELKEY_REPORTS_SALES_BREAKDOWN,
                                    path: Navigation.PATH_REPORTS_BREAKDOWN,
                                    isBreakdown: true
                                },
                                {
                                    labelKey: Navigation.LABELKEY_REPORTS_LOYALTY_POINTS,
                                    path: Navigation.PATH_REPORTS_LOYALTY_POINTS
                                },
                                {
                                    labelKey: 'Daily Sales',
                                    path: '/reports/daily-sales'
                                },
                                {
                                    labelKey: 'Control Sheet',
                                    path: '/reports/control-sheets',
                                    isControlSheet: true
                                },
                                {
                                    labelKey: 'PLU Sales',
                                    path: '/reports/plu-sales'
                                },
                                {
                                    labelKey: 'PLU Purchases',
                                    path: '/reports/plu-purchases'
                                },
                                {
                                    labelKey: 'PLU Reorders',
                                    path: '/reports/plu-reorders'
                                },
                                {
                                    labelKey: 'PLU Days On Hand',
                                    path: '/reports/plu-dayonhand'
                                },
                                {
                                    labelKey: 'Media Summary',
                                    path: '/reports/media-summary'
                                },
                                {
                                    labelKey: 'Weekly Summary',
                                    path: '/reports/weekly-summary'
                                },
                                {
                                    labelKey: 'Excises',
                                    path: '/reports/excises',
                                    isShowExcise: true
                                }
                            ]
                        },
                        {
                            labelKey: Navigation.LABELKEY_REPORTS_TIME_ATTENDANCE,
                            isShowTimeAndAttendance: true,
                            items: [
                                {
                                    labelKey: Navigation.LABELKEY_MENU_REPORTS,
                                    path: Navigation.PATH_REPORTS_TIME_ATTENDANCE
                                }
                            ]
                        },
                        {
                            labelKey: 'Payments',
                            permissions: ['can_viewpayments'],
                            items: [
                                {
                                    labelKey: 'Usage',
                                    path: '/reports/payments'
                                }
                            ]
                        }
                    ]
                },
                {
                    icon: 'ion-md-contacts',
                    labelKey: 'menu.employees',
                    path: '/employees',
                    permissions: ['can_viewemp'],
                    groups: [
                        {
                            labelKey: 'employees.general_group_title',
                            items: [
                                {
                                    labelKey: 'employees.list',
                                    path: '/employees/list',
                                }
                                // { // CREATE NEW USER IS DISABLED FOR NOW
                                //     labelKey: 'employees.new',
                                //     path: '/employees/create',
                                // }
                            ]
                        },
                        {
                            labelKey: 'employees.accesscontrol_group_title',
                            items: [
                                {
                                    labelKey: 'employees.register',
                                    path: '/employees/register',
                                    permissions: ['can_register']
                                },
                                {
                                    labelKey: 'employees.revoke',
                                    path: '/employees/revoke',
                                    permissions: ['can_revoke']
                                },
                                {
                                    labelKey: 'employees.requestpassword',
                                    path: '/employees/requestpassword',
                                },
                                {
                                    labelKey: 'employees.permissions',
                                    path: '/employees/permissions',
                                    permissions: ['can_register']
                                }
                            ]
                        }
                    ]
                },
                {
                    icon: 'ion-ios-card',
                    labelKey: 'menu.loyalty-cards',
                    path: '/loyalty',
                    permissions: ['can_viewloyalty'],
                    groups: [
                        {
                            labelKey: 'Management',
                            items: [
                                {
                                    labelKey: 'Search',
                                    path: '/loyalty/search',
                                },
                                {
                                    labelKey: 'Adjust Points',
                                    path: '/loyalty/adjust-points',
                                    permissions: ['can_editloyalty']
                                },
                                {
                                    labelKey: 'Transfer Points',
                                    path: '/loyalty/transfer-points',
                                    permissions: ['can_editloyalty']
                                },
                                {
                                    labelKey: 'Replace Card',
                                    path: '/loyalty/replace',
                                    permissions: ['can_editloyalty']
                                },
                                {
                                    labelKey: 'Fraud Check',
                                    path: '/loyalty/fraud-check'
                                }
                            ]
                        },
                        {
                            labelKey: 'Reports',
                            items: [
                                {
                                    labelKey: 'Usage summary',
                                    path: '/loyalty/usage-summary',
                                }
                            ]
                        }
                    ]
                },
            ];

            this.populateStripeMenu(result);
            this.populateMembershipMenu(result);
        }

        return result;

    }

    private populateStripeMenu(menu: TopNavItem[]): void {
        if (this.authService.hasPermission([CAN_LINK_PAYMENT])) {
            menu.push({
                icon: 'ion-ios-albums',
                labelKey: 'stripe',
                path: '/stripe/connect'
            });
        }
    }

    private populateMembershipMenu(menu: TopNavItem[]): void {
        this.currentUser = this.authService.getUser();

        if (this.currentUser && this.hasPermissions([CAN_VIEW_DASHBOARD])) {
            menu.push({
                icon: 'ion-ios-contact',
                path: '/membership/dashboard',
                labelKey: this.currentUser.UserName,
                groups: [
                    {
                        labelKey: 'Membership',
                        items: [
                            {
                                labelKey: 'Dashboard',
                                path: '/membership/dashboard',
                            },
                            {
                                labelKey: 'menu.logout',
                                path: '/membership/logout'
                            },
                        ]
                    }
                ]
            });
        } else {
            menu.push({
                icon: 'ion-ios-log-out',
                labelKey: 'menu.logout',
                path: '/membership/logout'
            });
        }

    }

    private setAsActive(url: string) {
        _.each(this.navigation, (topNav: TopNavItem, i: number) => {

            const hasMatchingTopPath = url.indexOf(topNav.path) === 0;

            topNav.isActive = hasMatchingTopPath;

            // set sub menu
            if (hasMatchingTopPath) {
                this.subMenu = this.navigation[i].groups;
            }

            // highlight active menu item
            _.each(topNav.groups, (group: LinkGroup) => {
                _.each(group.items, (item: LinkItem) => {
                    const hasMatchingPath = url.indexOf(item.path) > -1;

                    item.isActive = hasMatchingPath;
                });
            });
        });
    }

}

interface TopNavItem extends LinkItem {
    icon: string;
    name?: string;
    isActive?: boolean;
    groups?: LinkGroup[];
}

interface LinkGroup {
    labelKey: string;
    items: LinkItem[];
    permissions?: string[];
    isShowTimeAndAttendance?: boolean;
}

interface LinkItem {
    labelKey: string;
    path: string;
    isActive?: boolean;
    isBreakdown?: boolean;
    isControlSheet?: boolean;
    isShowExcise?: boolean;
    permissions?: string[];
}
