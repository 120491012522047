import { DateRange } from './../../shared/models';
import { LoyaltyCard, LoyaltyPoints, CardUsage, LoyaltyFraudCheck, LoyaltyUsageSummary, LoyaltyUsageResponse } from './../../../model/index';
import { Observable } from 'rxjs';
import { BaseHttp } from './baseHttp.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class LoyaltyService {

    constructor(private http: BaseHttp) {
    }

    search(search: String, value: String): Observable<LoyaltyCard[]> {
        return this.http.get(`/loyalty/search?${search}=${value}`)
                   .pipe(map(response => response.Details as LoyaltyCard[]));
    }

    getCard(cardNumber: string): Observable<LoyaltyCard> {
        return this.http.get(`/loyalty/${cardNumber}/details`)
                   .pipe(map(response => response as LoyaltyCard));
    }

    getPoints(cardNumber: string): Observable<LoyaltyPoints> {
        return this.http.get(`/loyalty/${cardNumber}/points`)
                   .pipe(map(response => response as LoyaltyPoints));
    }

    getUsage(cardNumber: String, date: DateRange): Observable<CardUsage[]> {
        return this.http.get(`/loyalty/${cardNumber}/use?FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}`)
                   .pipe(map(response => response.Locations as CardUsage[]));
    }

    getFraudCheck(date: DateRange, threshold: number): Observable<LoyaltyFraudCheck[]> {
        return this.http.get(`/loyalty/usage?FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}&Threshold=${threshold}`)
                   .pipe(map(response => response.Locations as LoyaltyFraudCheck[]));
    }

    getUsageSummary(date: DateRange): Observable<LoyaltyUsageSummary[]> {
        return this.http.get(`/points?FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}`)
                   .pipe(map(response => response.Locations as LoyaltyUsageSummary[]));
    }

    update(cardNumber: string, loyaltyCard: LoyaltyCard) {
        return this.http.post(`/loyalty/${cardNumber}/details`, loyaltyCard)
                   .pipe(map(response => response));
    }

    adjustPoints(cardNumber: string, points: number, location: number) {
        console.log(`location: ${location}`);
        return this.http.post(`/loyalty/${cardNumber}/points`, {
            CardNumber: cardNumber,
            Points: points,
            LocationId: location
        }).pipe(map(response => response));
    }

     transferPoints(cardNumberFrom: string, cardNumberTo: string, points: number, location: number) {
        return this.http.post(`/loyalty/${cardNumberFrom}/transfer/${cardNumberTo}`, {
            FromCard: cardNumberFrom,
            ToCard: cardNumberTo,
            Points: points,
            LocationId: location
        }).pipe(map(response => response));
    }

    replaceCard(oldCardNumber: string, replacemenCardNumber: string, comments: string) {
        return this.http.post(`/loyalty/${oldCardNumber}/replace/${replacemenCardNumber}`, {
            OldCard: oldCardNumber,
            ReplacementCard: replacemenCardNumber,
            Comments: comments
        }).pipe(map(response => response));
    }

    getLoyaltyUsage(interval: string, date: DateRange): Observable<LoyaltyUsageResponse> {
        return this.http.get(`/loyaltyusage?Interval=${interval}&FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}`)
                   .pipe(map(response => response));
    }
}
