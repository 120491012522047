<div id="c-mask" class="c-mask"></div>

<div class="page-nav">
    <div class="menu-btn-wrapper float-right">
        <a href="javascript://" id="menu-btn" class="btn btn-link">
            <i class="ion-md-menu"></i>
        </a>
    </div>
    <nav>
        <div class="logo">
            <img src="/assets/img/starrPOS-badge-logo.png" class="img-fluid" alt="">
        </div>
        <ul class="topnav">
            <ng-container *ngFor="let topNav of navigation">
                <li *ngIf="topNavPermission(topNav)">
                    <a [routerLink]="[topNav.path]" [ngClass]="{ 'active': topNav.isActive }">
                        <i [ngClass]="[topNav.icon]"></i>
                    </a>
                    <span class="tooltip">
                        <div class="tooltip-inner">{{topNav.labelKey | translate | loyaltyname}}</div>
                    </span>
                </li>
            </ng-container>
        </ul>
    </nav>
    <div class="subnav">
        <div *ngFor="let group of subMenu">
            <ng-container *ngIf="hasPermissions(group.permissions) && (!group.isShowTimeAndAttendance || showTimeAndAttendance())">
                <h4>{{group.labelKey | loyaltyname | translate}}</h4>

                <div *ngFor="let item of group.items">
                    <a *ngIf="hasPermissions(item.permissions) && (!item.isBreakdown || showBreakdown()) && (!item.isControlSheet || showControlSheet()) && (!item.isShowExcise || showExcises())"
                        [routerLink]="[item.path]" [ngClass]="{ 'active': item.isActive }">{{item.labelKey | translate}}</a>
                </div>
            </ng-container>
        </div>
        <p>&nbsp;</p>
        <button *ngIf="!isAdmin()" class="btn bt-link btn-block" (click)="translate()">Translate</button>
        <div *ngIf="currentUser" >
            <p>Hi {{currentUser.UserName}} !</p>
        </div>

    </div>
</div>

<nav id="c-menu--slide-right" class="c-menu c-menu--slide-right">
    <div class="c-menu__close-wrapper">
        <button class="c-menu__close btn btn-link">
            <i class="ion-md-close"></i>
        </button>
    </div>
    <ul>
        <ng-container *ngFor="let topNav of navigation">
            <li *ngIf="topNavPermission(topNav)">
                <a [routerLink]="[topNav.path]" class="top-nav-item" [ngClass]="{ 'active': topNav.isActive }">
                    <i [ngClass]="[topNav.icon]"></i>&nbsp; {{topNav.labelKey | loyaltyname | translate}}
                </a>
                <div *ngFor="let group of topNav.groups" class="sub-nav">
                    <ng-container *ngIf="hasPermissions(group.permissions) && (!group.isShowTimeAndAttendance || showTimeAndAttendance())">
                        <h6>{{group.labelKey | loyaltyname | translate }}</h6>
                        <ul>
                            <li *ngFor="let item of group.items">
                                <a *ngIf="hasPermissions(item.permissions) && (!item.isBreakdown || showBreakdown()) && (!item.isControlSheet || showControlSheet()) && (!item.isShowExcise || showExcises())"
                                    [routerLink]="[item.path]" class="c-menu-item" [ngClass]="{ 'active': item.isActive }">{{item.labelKey | translate}}</a>
                            </li>
                        </ul>
                    </ng-container>
                </div>
            </li>
        </ng-container>
    </ul>
</nav>