import { Injectable } from '@angular/core';
import { IUser, permission } from '../../model';

@Injectable()
export class Store {

    private keys = {
        userAuth: 'userAuth'
    };

    constructor() {

    }

    setUser(user: IUser) {
        localStorage.setItem(this.keys.userAuth, JSON.stringify(user) );
    }

    getUser(): IUser {
       return JSON.parse(localStorage.getItem(this.keys.userAuth));
    }

    removeUser(): void {
        localStorage.removeItem(this.keys.userAuth);
    }

    clearUser(): void {
        const usr = this.getUser();
        if (!!usr) {
            usr.Auth = '';
            usr.Config = null;
            usr.Permissions = [];
            usr.Roles = [];
            usr.SessionId = '';
            usr.UserId = '';
            usr.VenueId = '';
            this.setUser(usr);
        }
    }
}
