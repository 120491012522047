import { DateRange } from './../../shared/models';
import { Observable } from 'rxjs';
import { BaseHttp } from './baseHttp.service';
import { Injectable } from '@angular/core';
import { itemSalesPerLocation, salesPerLocation, location, SalesGroupResponse,
         SalesBistroCoversResponse, SalesMemberVisitsResponse, SalesTrendResponse,
         SalesBestSellersResponse, SalesReservationsResponse, SalesResponse,
         SalesCostsResponse, SalesGPMarginsResponse } from '../../../model';
import * as _ from 'underscore';
import { map } from 'rxjs/operators';

@Injectable()
export class SaleService {
    constructor(private http: BaseHttp) { }

    getSales(date: DateRange, locations: location[]): Observable<salesPerLocation[]> {
        return this.http.get(this.getUrlForSale('/sales/', date, locations))
                   .pipe(map((response: any) => response.Locations));
    }

    getItemSales(date: DateRange, locations: location[]): Observable<itemSalesPerLocation[]> {
        return this.http.get(this.getUrlForSale('/sales/selected/', date, locations))
                   .pipe(map((response: any) => response.Locations));
    }

    getSalesGroup(interval: string, date: DateRange, locn: string): Observable<SalesGroupResponse> {
        let locationQuery = '';

        if (locn) {
            locationQuery = `&Locations=${locn}`;
        }

        return this.http.get(`/sales/group?Interval=${interval}&FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}`)
                   .pipe(map(response => response));
    }

    getBistroCovers(date: DateRange, locn: string): Observable<SalesBistroCoversResponse> {
        let locationQuery = '';

        if (locn) {
            locationQuery = `&Locations=${locn}`;
        }

        return this.http.get(`/sales/bistrocovers?FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}`)
                   .pipe(map(response => response));
    }

    getMemberVisits(date: DateRange, locn: string): Observable<SalesMemberVisitsResponse> {
        let locationQuery = '';

        if (locn) {
            locationQuery = `&Locations=${locn}`;
        }

        return this.http.get(`/sales/membervisits?FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}`)
                   .pipe(map(response => response));
    }

    getSalesTrend(date: DateRange, locn: string, trendDate: DateRange): Observable<SalesTrendResponse> {
        let locationQuery = '';
        let trendQuery = '';

        if (locn) {
            locationQuery = `&Locations=${locn}`;
        }

        if (!trendDate.isEmpty()) {
            trendQuery = `&TrendDate=${trendDate.startDate.format('YYYY-MM-DD')}`;
        }

        return this.http.get(`/sales/trend?FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}${trendQuery}`)
                   .pipe(map(response => response));
    }

    getBestSeller(date: DateRange, locn: string): Observable<SalesBestSellersResponse> {
        let locationQuery = '';

        if (locn) {
            locationQuery = `&Locations=${locn}`;
        }

        return this.http.get(`/sales/bestsellers?FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}`)
                   .pipe(map(response => response));
    }

    getSalesReservations(date: DateRange, locn: string): Observable<SalesReservationsResponse> {
        let locationQuery = '';

        if (locn) {
            locationQuery = `&Locations=${locn}`;
        }

        return this.http.get(`/sales/reservations?FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}`)
                   .pipe(map(response => response));
    }

    getProductGroup(interval: string, date: DateRange, locn: string): Observable<SalesResponse> {
        let locationQuery = '';

        if (locn) {
            locationQuery = `&Locations=${locn}`;
        }

        return this.http.get(`/sales/productgroup?Interval=${interval}&FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}`)
                   .pipe(map(response => response));
    }

    getDepartment(interval: string, date: DateRange, locn: string): Observable<SalesResponse> {
        let locationQuery = '';

        if (locn) {
            locationQuery = `&Locations=${locn}`;
        }

        return this.http.get(`/sales/department?Interval=${interval}&FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}`)
                   .pipe(map(response => response));
    }

    getCategory(interval: string, date: DateRange, locn: string): Observable<SalesResponse> {
        let locationQuery = '';

        if (locn) {
            locationQuery = `&Locations=${locn}`;
        }

        return this.http.get(`/sales/category?Interval=${interval}&FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}`)
                   .pipe(map(response => response));
    }

    getEmployee(interval: string, date: DateRange, locn: string): Observable<SalesResponse> {
        let locationQuery = '';

        if (locn) {
            locationQuery = `&Locations=${locn}`;
        }

        return this.http.get(`/sales/employee?Interval=${interval}&FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}`)
                   .pipe(map(response => response));
    }

    getPromotion(interval: string, date: DateRange, locn: string): Observable<SalesResponse> {
        let locationQuery = '';

        if (locn) {
            locationQuery = `&Locations=${locn}`;
        }

        return this.http.get(`/sales/promotion?Interval=${interval}&FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}`)
                   .pipe(map(response => response));
    }

    getDiscount(interval: string, date: DateRange, locn: string): Observable<SalesResponse> {
        let locationQuery = '';

        if (locn) {
            locationQuery = `&Locations=${locn}`;
        }

        return this.http.get(`/sales/discount?Interval=${interval}&FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}`)
                   .pipe(map(response => response));
    }

    getSurcharge(interval: string, date: DateRange, locn: string): Observable<SalesResponse> {
        let locationQuery = '';

        if (locn) {
            locationQuery = `&Locations=${locn}`;
        }

        return this.http.get(`/sales/surcharge?Interval=${interval}&FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}`)
                   .pipe(map(response => response));
    }

    getWastage(interval: string, date: DateRange, locn: string): Observable<SalesResponse> {
        let locationQuery = '';

        if (locn) {
            locationQuery = `&Locations=${locn}`;
        }

        return this.http.get(`/sales/wastage?Interval=${interval}&FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}`)
                   .pipe(map(response => response));
    }

    getCosts(interval: string, date: DateRange, locn: string): Observable<SalesCostsResponse> {
        let locationQuery = '';

        if (locn) {
            locationQuery = `&Locations=${locn}`;
        }

        return this.http.get(`/sales/costs?Interval=${interval}&FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}`)
                   .pipe(map(response => response));
    }

    getGpMargins(locn: string): Observable<SalesGPMarginsResponse> {
        let locationQuery = '';

        if (locn) {
            locationQuery = `?Locations=${locn}`;
        }

        return this.http.get(`/sales/gpmargins${locationQuery}`)
                   .pipe(map(response => response));
    }

    private getUrlForSale(base: string, date: DateRange, locations: location[]) {

        locations = locations || [];

        const locationIds = _.map(locations, function (l) { return l.LocationId; });
        const locationQuery = `&locations=${locationIds.join(',')}`;

        if (date.getNumberOfDays() === 0) {
            return `${base}interval?Date=${date.startDate.format('YYYY-MM-DD')}${locationQuery}`;
        }

        let action = 'day';

        if (date.periodType === 'month') {
            action = 'month';
        }

        if (date.periodType === 'year') {
            action = 'year';
        }

        if (date.periodType === 'week') {
            action = 'week';
        }

        return `${base}${action}?FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}`;
    }
}
