export const LANG_JP_TRANS = {

    // menu
    'menu.dashboard': '計器盤',
    'menu.reports': '報告',
    'menu.loyalty-cards': 'ロイヤリティカード',
    'menu.logout': 'ログアウト',
    'menu.employees': '従業員',


    // calendar
    'calendar_hour': '時',
    'calendar_day': '日',
    'calendar_week': '週',
    'calendar_month': '月',
    'calendar_year': '年',

    // reportViewType
    'reportViewType_summary': '概要',
    'reportViewType_detail': '詳細',

    // reports
    'report_subtitle': '報告',
    'report_title': '販売概要',
    'reports.sales_group_title': '販売',
    'reports.sales_item_1': `報告`,
    'reports.sales_breakdown': '売上高内訳',
    'reports.time_attendance': 'T & A',
    'reports.loyalty_points': 'ロイヤリティポイント',
    'reports.invoices_group_title': '請求書',
    'reports.invoices_item_1': '請求書を一覧表示する',
    'reports.export_to_csv': '輸出する',

    // employees
    'employees.general_group_title': '会員',
    'employees.accesscontrol_group_title': '行動',
    'employees.list': 'リスト',
    'employees.new': '作成する',
    'employees.register': 'アクセスを有効にする',
    'employees.revoke': 'アクセス権を取り消す',
    'employees.requestpassword': 'パスワードを要求する',
    'employees.permissions': '許可',
    'Employee': '従業員',
    'Locations': '場所',
    'Location': 'ロケーション',
    'Loc.': 'ロケーション',
    'First Name' : 'ファーストネーム',
    'NickName' : 'ニックネーム',
    'Surname' : '姓',
    'User Name' : 'ユーザー名',
    'Display Name' : '表示名',
    'Password' : 'パスワード',

    'Name': '名',
    'Email' : 'Eメール',
    'Active' : 'アクティブ',
    'Act.' : 'アクティブ',
    'Registered' : '登録済み',
    'Reg.' : '登録済み',
    'Actions' : '行動',
    'Enable' : '有効にする',
    'Revoke' : '取り消す',
    'Request' : '要求',

    // lists
    'total': '合計',
    'region_title': '地域ごとの要約',
    'region_store': 'ストアごとのサマリー',
    'points_per_store': 'ストアごとのポイント',
    'column_loyalty_points': 'ロイヤリティポイント',
    'column_adjustment': '調整',
    'column_voucher_sales': 'バウチャーセールス',
    'column_sales_reports': 'セールスレポート',
    'column_member_card': '会員カード',
    'column_total_points': '合計点',
    'column_region': '領域',
    'column_store': '格納',
    'column_date': '日付',
    'column_deliveries': '配達',
    'column_takeaways': 'テイクアウト',
    'column_totals': '合計',
    'column_loyalty': 'ロイヤリティー',
    'column_sales': '販売',
    'column_qty': '量',
    'column_customers': '顧客',
    'column_amount': '量 ($)',
    'column_shift': 'シフト',
    'column_name': '名',
    'column_period': '期間',
    'column_points_accrued': '+ (D)',
    'column_points_redeemed': '- (C)',
    'column_adjustment_accrued': '+ (F)',
    'column_adjustment_redeemed': '- (E)',
    'column_twenty_dollar': '$20',
    'column_twenty_five_dollar': '$25',
    'column_fifty_dollar': '$50',
    'column_one_hundred_dollar': '$100',
    'column_total_voucher_sales': '合計点 (K)',
    'column_sales_reports_point': 'ポイント',
    'column_sales_reports_cnt': 'Cnt',
    'column_member_card_point': 'ポイント',
    'column_member_card_cnt': 'Cnt',
    'column_total_points_ce': '(C+E)',
    'column_total_points_dfk': '(D+F+K)',
    'column_monday': '月曜',
    'column_tuesday': '火曜日',
    'column_wednesday': '水曜日',
    'column_thursday': '木曜日',
    'column_friday': '金曜日',
    'column_saturday': '土曜日',
    'column_sunday': '日曜日',
    'column_employee': '従業員',
    'column_roster': 'ロスター',
    'column_claimed': '請求された',
    'column_actual': '実際の',
    'column_hours': '時間',

     // metric options
    'Sales Amount' : '売上高',
    'Takeaways' : 'テイクアウト',
    'Deliveries' : '配達',
    'Totals' : '合計',

    // csv
    'id': '身元',
    'name': '名',
    'date': '日付',
    'deliveryCount': '配信数',
    'deliveryLoyalty': '配達忠誠',
    'deliverySales': '配達販売',
    'takeawayCount': 'テイクオーヴカウント',
    'takeawayLoyalty': '持ち帰りロイヤルティ',
    'takeawaySales': 'テイクアウト販売',
    'totalCount': '総数',
    'totalCustomers': '総顧客',
    'totalLoyalty': 'トータルロイヤリティ',
    'totalsales': '総売上',

    // alertify messages
    'formSaved' : '変更は保存されました。',

    // general
    'Please select...': '選んでください...',
    'Select items': 'アイテムを選択',
    'Select Locations': '場所を選択',
    'Select Locations...': '場所を選択...',
    'Select Employee': '従業員を選択',
    'Select Employee...': '従業員を選択...',
     'Select Permissions...': '権限を選択...',
    'Select Permissions': '権限を選択',
    'Select metrics...': 'メトリックの選択...',
    'Select metrics': 'メトリックの選択',

    // loyalty
    'total cannot be less than zero': '合計はゼロより小さくなることはできません',
    'Search Loyalty Card': 'ロイヤリティーカードを検索',
    'Adjust Points': 'ポイントを調整する',
    'Transfer Points': '転送ポイント',
    'Fraud Check': '詐欺チェック',
    'Usage summary': '使用法の概要',
    'Management': '管理',
    'Reports': 'レポート',
    'Period': '期間',
    'Threshold': '閾値',
    'Date': '日付',
    'Card Number': 'カード番号',
    'Count Used': '使用回数',
    'From Card Number': 'カード番号から',
    'To Card Number': 'カード番号に',
    'Filter by': 'フィルタリングする',
    'Loyalty Count': 'ロイヤリティ数',
    'Accrued': '未払い',
    'Redeemed': '救済された',
    'Voucher Amount': 'バウチャー額',
    'Voucher Count': 'バウチャーカウント',
};
