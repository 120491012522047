import { TranslateService } from './../core/translations/translate.service';
import { Injectable } from '@angular/core';
import * as alertify from 'alertifyjs';

@Injectable()
export class Messenger {

    constructor(private translateService: TranslateService) {
    }

    public success(message: string) {
        alertify.success(this.translateService.instant(message));
    }
    public error(message: string) {
        alertify.error(this.translateService.instant(message));
    }
    public warning(message: string) {
        alertify.alert(this.translateService.instant(message));
    }

}