// app/translate/translation.ts

import { InjectionToken } from '@angular/core';

// import translations
import { LANG_EN_TRANS } from './lang-en';
import { LANG_JP_TRANS } from './lang-jp';


// translation token
export const TRANSLATIONS = new InjectionToken('translations');

// all translations
export const dictionary = {
    'en': LANG_EN_TRANS,
    'jp': LANG_JP_TRANS
};

// providers
export const TRANSLATION_PROVIDERS = [
    { provide: TRANSLATIONS, useValue: dictionary },
];
