import { Observable } from 'rxjs';
import { BaseHttp } from './baseHttp.service';
import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { ApiKey, ApiKeyCreate, VenueSupplierUser } from 'model';
import { Store } from '../store';
import { map } from 'rxjs/operators';

@Injectable()
export class ApiKeyService {
    constructor(
        private http: BaseHttp,
        private store: Store
    ) {
    }

    get(): Observable<ApiKey[]> {
        return this.http
                   .get('/patronapi2/keys')
                   .pipe(map((response: any) => response.Users as ApiKey[]));
    }

    getById(key: string): Observable<ApiKey> {
        return this.http
                   .get(`/patronapi2/${key}/keys`)
                   .pipe(map((response: any) => response.Users[0] as ApiKey));
    }

    getVenueSuppliers(key: string): Observable<VenueSupplierUser[]> {
        return this.http
                   .get(`/user/${key}/venues/suppliers`)
                   .pipe(map((response: any) => response.Users as VenueSupplierUser[]));
    }

    create(user: ApiKeyCreate): Observable<ApiKey> {
        return this.http
                   .post(`/patronapi2/keys`, {
                       User: user
                   })
                   .pipe(map((response: any) => {
                       const users = response.Users as ApiKey[];
                       if (users !== null && users.length > 0) {
                           return users[0];
                       } else {
                           return null;
                       }
                   }));
    }

    createVenueSupplier(user: string, venue: string, supplier: number) {
        return this.http
                   .post(`/venue/${venue}/suppliers/${supplier}/Users`, {
                       UserId: user
                   });
    }

    setKey(key: string) {
        const usr = this.store.getUser();
        usr.ApiKey = key;
        this.store.setUser(usr);
    }

    getKey(): string {
        const usr = this.store.getUser();
        if (usr) {
            return usr.ApiKey;
        }
        return null;
    }
}
