import { SpinnerComponent } from './spinner.directive';
import { ErrorSummaryComponent } from './errorSummary.directive';
import { AlertifyComponent } from './alertify.directive';
import { Messenger } from './messenger';
import { InputErrorComponent } from './inputError.directive';
import { ExtendedInputComponent } from './extendedInput.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TranslatePipe } from './translate.pipe';
import { LoyaltyNamePipe } from './loyaltyname.pipe';
import { DateRangePickerComponent } from './dateRangePicker.directive';
import { MultipleSelectorComponent } from './multipleSelector.directive';
import { UriToStringPipe } from './uri-to-string.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule
    ],
    declarations: [
        TranslatePipe, 
        LoyaltyNamePipe,
        UriToStringPipe,
        AlertifyComponent, 
        ErrorSummaryComponent,
        DateRangePickerComponent, 
        MultipleSelectorComponent, 
        ExtendedInputComponent, 
        InputErrorComponent,
        SpinnerComponent],
    providers:[Messenger],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        TranslatePipe,
        LoyaltyNamePipe,
        UriToStringPipe,
        AlertifyComponent,
        DateRangePickerComponent,
        MultipleSelectorComponent,
        ExtendedInputComponent,
        InputErrorComponent,
        ErrorSummaryComponent,
        SpinnerComponent
    ]
})
export class SharedModule {

}