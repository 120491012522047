import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '../store';
import { BaseHttp } from './baseHttp.service';
import { location,
         PaymentTokensResponse,
         DeputyTokensResponse,
         InvolvTokensResponse,
         ShopifyTokensResponse,
         LocationConfig
        } from '../../../model';
import { map } from 'rxjs/operators';

@Injectable()
export class LocationService {

    constructor(private http: BaseHttp, private store: Store) {
    }

    get(): Observable<location[]> {

        const user = this.store.getUser();

        return this.http.get(`/userlocations?userid=${user.UserId}`)
                   .pipe(map((response: any) => response.Locations));
    }

    postPaymentTokens(locationId: string, authorizationCode: string): Observable<PaymentTokensResponse> {
        return this.http.post(`/location/${locationId}/paymenttokens`, {
            LocationId: locationId,
            AuthorizationCode: authorizationCode
        })
            .pipe(map((response: any) => response.Locations));
    }

    deletePaymentTokens(locationId: string): Observable<PaymentTokensResponse> {
        return this.http.delete(`/location/${locationId}/paymenttokens`)
                   .pipe(map((response: any) => response.Locations));
    }

    postDeputyTokens(locationId: string, authorizationCode: string, redirectUri: string): Observable<DeputyTokensResponse> {
        return this.http.post(`/location/${locationId}/deputytokens`, {
            LocationId: locationId,
            AutorizationCode: authorizationCode,
            RedirectURI: redirectUri
        })
        .pipe(map((response: any) => response.Locations));
    }

    deleteDeputyTokens(locationId: string): Observable<DeputyTokensResponse> {
        return this.http.delete(`/locations/${locationId}/deputytokens`)
                   .pipe(map((response: any) => response.Locations));
    }

    postInvolvTokens(locationId: string, authorizationCode: string, redirectUri: string): Observable<InvolvTokensResponse> {
        return this.http.post(`/locations/${locationId}/involvtokens`, {
            LocationId: locationId,
            AuthorizationCode: authorizationCode,
            RedirectURI: redirectUri
        })
        .pipe(map((response: any) => response.Locations));
    }

    deleteInvolvTokens(locationId: string): Observable<InvolvTokensResponse> {
        return this.http.delete(`/locations/${locationId}/involvtokens`)
                   .pipe(map((response: any) => response.Locations));
    }

    postShopifyTokens(locationId: string, authorizationCode: string, redirectUri: string): Observable<ShopifyTokensResponse> {
        return this.http.post(`/location/${locationId}/shopifytokens`, {
            LocationId: locationId,
            AutorizationCode: authorizationCode,
            RedirectURI: redirectUri
        })
        .pipe(map((response: any) => response.Locations));
    }

    deleteShopifyTokens(locationId: string): Observable<ShopifyTokensResponse> {
        return this.http.delete(`/locations/${locationId}/shopifytokens`)
                   .pipe(map((response: any) => response.Locations));
    }

    getConfig(id: string): Observable<LocationConfig[]> {
        return this.http
                   .get(`/venue/${id}/locations/config`)
                   .pipe(map((response: any) => response.Locations));
    }

    saveConfig(id: string, locations: LocationConfig[]): Observable<LocationConfig[]> {
        return this.http
                   .put(`/venue/${id}/locations/config`, {
                       Locations: locations
                   })
                   .pipe(map((response: any) => response.Locations));
    }

    getStripeInfo(): Observable<String> {
        return this.http
                   .get(`/info/stripe`)
                   .pipe(map((response: any) => response.StripeClientId));
    }
}
