export class Navigation {
    static readonly TOP_NAV_REPORTS = 'reports';
    static readonly LABELKEY_MENU_REPORTS = 'menu.reports';
    static readonly LABELKEY_REPORTS_SALES_GROUP_TITLE = 'reports.sales_group_title';
    static readonly LABELKEY_REPORTS_SALES_ITEM_1 = 'reports.sales_item_1';
    static readonly LABELKEY_REPORTS_SALES_BREAKDOWN = 'reports.sales_breakdown';
    static readonly LABELKEY_REPORTS_LOYALTY_POINTS = 'reports.loyalty_points';
    static readonly LABELKEY_REPORTS_TIME_ATTENDANCE = 'reports.time_attendance';
    static readonly PATH_REPORTS = '/reports';
    static readonly PATH_REPORTS_BREAKDOWN = '/reports/breakdown';
    static readonly PATH_REPORTS_SALES = '/reports/sales';
    static readonly PATH_REPORTS_LOYALTY_POINTS = '/reports/loyalty-points';
    static readonly PATH_REPORTS_TIME_ATTENDANCE = '/reports/time-attendance';
    static readonly PATH_EMPLOYEES = '/employees';
    static readonly PATH_ADMIN = '/admin';
    static readonly PATH_MEMBERSHIP_DASHBOARD = '/membership/dashboard';
    static readonly PATH_SELECT_VENUE = '/membership/selectvenue';
    static readonly ICON_ION_IOS_STATS = 'ion-ios-stats';
}
