import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IUser, AuthRequest, permission, venueconfig, UserVenue, SelectUserVenueResponse, ErrorModel, EmployeeNameResponse } from '../../../model';
import { Store } from '../store';
import { BaseHttp } from '../services/baseHttp.service';
import * as _ from 'underscore';
import { CAN_VIEW_ROSTER, CAN_VIEW_TIMESHEET } from 'app/shared/constants/permission';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService implements IAuthService {

    constructor(private http: BaseHttp, private store: Store) {
    }

    isAuthenticated(): boolean {
        const isAuthenticated = !!this.getUser();
        return isAuthenticated;
    }

    getUser(): IUser {
        try {
            return this.store.getUser();
        } catch (error) {
            return null;
        }
    }

    clear() {
        this.store.removeUser();
    }

    login(request: AuthRequest): Observable<IUser> {

        this.store.removeUser();

        return this.http
                   .post(`/auth/credentials`, request)
                   .pipe(map((response: any) => {
                        const user = response as IUser;
                        if (!!user) {
                            user.Auth = btoa(`${request.UserName}:${request.Password}`);
                            this.store.setUser(user);
                        }
                        return user;
                    }));
    }

    resetPassword(user: String, url: string): Observable<ErrorModel> {
        const postData = { UserId: user, PasswordUrl: url };
        return this.http
                .post(`/users/requestpassword`, postData)
                .pipe(map((response: any) => response));

    }

    getByToken(token: String): Observable<EmployeeNameResponse> {
        return this.http
                   .get(`/employees/${token}/name`)
                   .pipe(map((response: any) => response));
    }

    changePassword(password: String, token: String): Observable<ErrorModel> {
        const postData = { Password: password };
        return this.http
                   .post(`/employees/${token}/changepassword`, postData)
                   .pipe(map((response: any) => response));
    }

    getUserVenues(user: IUser): Observable<UserVenue[]> {
        return this.http.get(`/users/${user.UserId}/venues`)
                   .pipe(map((response: any) => {
                        const venues = response.Venues;
                        const u = this.store.getUser();
                        u.Venues = venues;
                        this.store.setUser(u);
                        return venues;
                   }));
    }

    selectUserVenue(user: IUser, venue: UserVenue): Observable<IUser> {
        return this.http.post(`/users/${user.UserId}/venues/${venue.VenueId}/select`, {
            EmployeeId: venue.EmployeeId
        })
        .pipe(map((response: any) => {
            const resp = response as SelectUserVenueResponse;
            user.Token = resp.Token;
            this.store.setUser(user);
            return user;
        }));
    }

    getPermissions(user: IUser): Observable<permission[]> {
        return this.http.get(`/users/${user.UserId}`)
            .pipe(map((response: any) => {
                const result = response;
                const permissions = result.Permissions as permission[];
                if (!!permissions) {
                    user.Permissions = permissions;
                }
                const roles = result.Roles as string[];
                if (!!roles) {
                    user.Roles = roles;
                }
                const cnfg = result.Config as venueconfig;
                if (!!cnfg) {
                    user.Config = cnfg;
                }
                user.UserId = result.UserId;
                user.UserName = result.UserName;
                user.DisplayName = result.DisplayName;
                this.store.setUser(user);
                return permissions;
            }));

    }

    hasPermission(tags: string[]): boolean {

        const user = this.store.getUser();

        if (!user || (tags.length && !user.Permissions)) {
            return false;
        }

        let allowed = true;
        tags.forEach(tag => {
            if (user.Permissions.findIndex(item => item.Tag === tag) === -1) {
                return allowed = false;
            }
        });

        return allowed;
    }

    hasRole(role: string): boolean {
        const user = this.store.getUser();
        if (!user || !role || !user.Roles) {
            return false;
        }
        let allowed = false;
        if (user.Roles.findIndex(item => item === role) >= 0) {
            allowed = true;
        }
        return allowed;
    }

    logout() {
        this.store.clearUser();
    }

    setVenue(id: string) {
        const user = this.store.getUser();
        user.VenueId = id;
        this.store.setUser(user);
    }

    getVenue(): string {
        const user = this.store.getUser();
        return user.VenueId;
    }

    showBreakdownPage(): boolean {
        const user = this.store.getUser();
        return !user.Config || user.Config.ShowBreakdownPage;
    }

    showControlSheet(): boolean {
        const user = this.store.getUser();
        return !user.Config || user.Config.ShowControlSheet;
    }

    showExcises(): boolean {
        const user = this.store.getUser();
        return !user.Config || user.Config.ShowExcise;
    }

    showTimeAndAttendance(): boolean {
        const user = this.store.getUser();
        if (user && user.Config && user.Config.ShowTimeAndAttendanceModule) {
            const permissions = _.find(user.Permissions, function (perm) {
                return perm.Tag === CAN_VIEW_ROSTER || perm.Tag === CAN_VIEW_TIMESHEET;
            });

            if (permissions) {
                return true;
            }
        }
        return false;
    }

    setStripeRedirectUri(uri: string) {
        const user = this.store.getUser();
        user.StripeRedirectUri = uri;
        this.store.setUser(user);
    }

    getStripeRedirectUri(): string {
        const user = this.store.getUser();
        return user.StripeRedirectUri;
    }

    setStripeLocationId(locationId: string) {
        const user = this.store.getUser();
        user.StripeLocationId = locationId;
        this.store.setUser(user);
    }

    getStripeLocationId(): string {
        const user = this.store.getUser();
        return user.StripeLocationId;
    }

    setDeputyRedirectUri(uri: string) {
        const user = this.store.getUser();
        user.DeputyRedirectUri = uri;
        this.store.setUser(user);
    }

    getDeputyRedirectUri(): string {
        const user = this.store.getUser();
        return user.DeputyRedirectUri;
    }

    setDeputyLocationId(locationId: string) {
        const user = this.store.getUser();
        user.DeputyLocationId = locationId;
        this.store.setUser(user);
    }

    getDeputyLocationId(): string {
        const user = this.store.getUser();
        return user.DeputyLocationId;
    }

    getInvolvRedirectUri(): string {
        const user = this.store.getUser();
        return user.InvolvRedirectUri;
    }

    setInvolvRedirectUri(uri: string) {
        const user = this.store.getUser();
        user.InvolvRedirectUri = uri;
        this.store.setUser(user);
    }

    setInvolvLocationId(locationId: string) {
        const user = this.store.getUser();
        user.InvolvLocationId = locationId;
        this.store.setUser(user);
    }

    getInvolvLocationId(): string {
        const user = this.store.getUser();
        return user.InvolvLocationId;
    }

    setShopifyRedirectUri(uri: string) {
        const user = this.store.getUser();
        user.ShopifyRedirectUri = uri;
        this.store.setUser(user);
    }

    getShopifyRedirectUri(): string {
        const user = this.store.getUser();
        return user.ShopifyRedirectUri;
    }

    setShopifyLocationId(locationId: string) {
        const user = this.store.getUser();
        user.ShopifyLocationId = locationId;
        this.store.setUser(user);
    }

    getShopifyLocationId(): string {
        const user = this.store.getUser();
        return user.ShopifyLocationId;
    }

}

export interface IAuthService {
    isAuthenticated(): boolean;
    hasPermission(tags: string[]): boolean;
    login(request: AuthRequest): Observable<IUser>;
    logout();
    getUser(): IUser;
    hasRole(role: string): boolean;
    setVenue(id: string);
}
