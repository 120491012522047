export const environment = {
  production: true,
  clientId: 'sushi-train',
  //
  // LIVE
  //
  apiEndpoint: 'https://patron.starrtec.com.au',
  baseUrl: 'https://cloud.starrtec.com.au',
  redirectUrl: 'https://cloud.starrtec.com.au',
  stripeClientId: 'ca_DLEWFSkRYTZcU8QwJdRGzReGFilExOFt',

  //
  // SANDBOX
  //
  // apiEndpoint: 'https://sandbox.starrtec.com.au',
  // baseUrl: 'https://sandbox.starrtec.com.au',
  // redirectUrl: 'https://sandbox.starrtec.com.au',
  // stripeClientId: 'ca_DLEWThoEhZNi9KZ3nxleBuM4Z72LDH8p',
  //
  // SANDBOX - LIVE
  //
  // apiEndpoint: 'https://patron.starrtec.com.au',
  // baseUrl: 'https://sandbox.starrtec.com.au',
  // redirectUrl: 'https://sandbox.starrtec.com.au',

  //
  // MRL
  //
  // apiEndpoint: 'https://catering.mrl.com.au',
  // baseUrl: 'https://cloud.catering.mrl.com.au',
  // redirectUrl: 'https://cloud.catering.mrl.com.au',

  deputyClientId: '039fa86a4257d832eaa174c6eb80498ab6be8ff3'

};
