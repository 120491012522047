<div [ngClass]="{ hide: !shouldShow }">
    <navigation-menu></navigation-menu>
</div>

<router-outlet></router-outlet>

<footer *ngIf="shouldShow" class="footer">
    <div class="container footer-container">
        <span class="text-muted">Ver {{appVersion}} &copy; Starrtec Pty Ltd {{copyDate}}</span>
    </div>
</footer>