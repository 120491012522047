import { venue, venueconfig, category, NonSale, VenuesResponse,
         VenueConfigPaymentInfoResponse, VenueProductGroupsResponse, VenueOrderLocationsResponse,
         VenueConnectionsConfig, VenueShowConfig, Settings, VenueMarketplaceSettings, VenueSupplierInfo, VenueTyroSettings, VenueDeliverectSettings
        } from './../../../model/index';
import { Observable } from 'rxjs';
import { BaseHttp } from './baseHttp.service';
import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { map } from 'rxjs/operators';
import { ThisReceiver } from '@angular/compiler';

@Injectable()
export class VenueService {
    constructor(private http: BaseHttp) {
    }

    get(): Observable<venue[]> {
        return this.http.get('/locations')
                   .pipe(map((response: any) => response.Locations as venue[]));
    }

    getById(id: string): Observable<venue> {
        return this.http.get(`/locations/${id}`)
                   .pipe(map((response: any) => response.Locations[0] as venue));
    }

    getConfig(id: string): Observable<venueconfig> {
        return this.http.get(`/venue/${id}/config`)
                   .pipe(map((response: any) => response.Config as venueconfig));
    }

    saveConfig(id: string, buf: venueconfig) {
        return this.http.post(`/venue/${id}/config`, {
            Config: buf
        })
            .pipe(map((response: any) => response));
    }

    getConnectionsConfig(id: string): Observable<VenueConnectionsConfig> {
        return this.http
                    .get(`/venue/${id}/config/connections`)
                    .pipe(map((response: any) => response.Config as VenueConnectionsConfig));
    }

    saveConnectionsConfig(id: string, buf: VenueConnectionsConfig) {
        return this.http
                   .post(`/venue/${id}/config/connections`, {
                       Config: buf
                   })
                   .pipe(map((response: any) => response));
    }

    getShowConfig(id: string): Observable<VenueShowConfig> {
        return this.http
                   .get(`/venue/${id}/config/shows`)
                   .pipe(map((response: any) => response.Config as VenueShowConfig));
    }

    saveShowConfig(id: string, buf: VenueShowConfig) {
        return this.http
                   .post(`/venue/${id}/config/shows`, {
                       Config: buf
                   })
                   .pipe(map((response: any) => response));
    }

    getSettings(id: string): Observable<Settings> {
        return this.http
                   .get(`/venue/${id}/config/settings`)
                   .pipe(map((response: any) => response.Settings as Settings));
    }

    saveSettings(id: string, settings: Settings) {
        return this.http
                   .post(`/venue/${id}/config/settings`, {
                       Settings: settings
                   })
                   .pipe(map((response: any) => response));
    }

    getCategories(id: string): Observable<category[]> {
        return this.http.get(`/venue/${id}/categories`)
                   .pipe(map((response: any) => response.Categories as category[]));
    }

    getNonSales(id: string): Observable<NonSale[]> {
        return this.http.get(`/venue/${id}/nonsales`)
                   .pipe(map((response: any) => response.NonSales as NonSale[]));
    }

    getVenueInfo(): Observable<VenuesResponse> {
        return this.http.get(`/venueinfo`)
                   .pipe(map((response: any) => response as VenuesResponse));
    }

    getProductGroups(id: string): Observable<VenueProductGroupsResponse> {
        return this.http.get(`/venue/${id}/productgroups`)
                   .pipe(map((response: any) => response));
    }

    // Payments
    paymentTokens(id: string, authorizationCode: string): Observable<any> {
        return this.http.post(`/venue/${id}/paymenttokens`, {
            VenueId: id,
            AuthorizationCode: authorizationCode
        });
    }

    paymentTokensAtLocation(venueId: string, locationId: string, authorizationCode: string): Observable<any> {
        return this.http.post(`/venue/${venueId}/locations/${locationId}/paymenttokens`, {
            VenueId: venueId,
            LocationId: locationId,
            AuthorizationCode: authorizationCode
        });
    }

    deletePaymentTokens(id: string): Observable<any> {
        return this.http.delete(`/venue/${id}/paymenttokens`);
    }

    deletePaymentTokensAtLocation(venueId: string, locationId: string): Observable<any> {
        return this.http.delete(`/venue/${venueId}/locations/${locationId}/paymenttokens`);
    }

    venueAdminPaymentTokens(authorizationCode: string): Observable<any> {
        return this.http.post(`/paymenttokens`, {
            AuthorizationCode: authorizationCode
        });
    }

    venueAdminDeletePaymentTokens(): Observable<any> {
        return this.http.delete(`/paymenttokens`);
    }

    deleteDeputyTokens(id: string): Observable<any> {
        return this.http.delete(`/venue/${id}/deputytokens`);
    }

    deputyTokens(id: string, authorizationCode: string, redirectUri: string): Observable<any> {
        return this.http.post(`/venue/${id}/deputytokens`, {
            VenueId: id,
            AuthorizationCode: authorizationCode,
            RedirectURI: redirectUri
        });
    }

    deputyTokensAtLocation(venueId: string, locationId: string, authorizationCode: string, redirectUri: string): Observable<any> {
        return this.http.post(`/venue/${venueId}/locations/${locationId}/deputytokens`, {
            VenueId: venueId,
            LocationId: locationId,
            AuthorizationCode: authorizationCode,
            RedirectURI: redirectUri
        });
    }

    deleteDeputyTokensAtLocation(venueId: string, locationId: string): Observable<any> {
        return this.http.delete(`/venue/${venueId}/locations/${locationId}/deputytokens`);
    }

    deleteInvolvTokens(id: string): Observable<any> {
        return this.http.delete(`/venue/${id}/involvtokens`);
    }

    involvTokens(id: string, authorizationCode: string, redirectUri: string): Observable<any> {
        return this.http.post(`/venue/${id}/involvtokens`,
        {
            VenueId: id,
            AuthorizationCode: authorizationCode,
            RedirectURI: redirectUri
        });
    }

    involvTokensAtLocation(venueId: string, locationId: string, authorizationCode: string, redirectUri: string): Observable<any> {
        return this.http.post(`/venue/${venueId}/locations/${locationId}/involvtokens`, {
            VenueId: venueId,
            LocationId: locationId,
            AuthorizationCode: authorizationCode,
            RedirectURI: redirectUri
        });
    }

    deleteInvolvTokensAtLocation(venueId: string, locationId: string): Observable<any> {
        return this.http.delete(`/venue/${venueId}/locations/${locationId}/involvtokens`);
    }

    deleteShopifyTokens(id: string): Observable<any> {
        return this.http.delete(`/venue/${id}/shopifytokens`);
    }

    shopifyTokens(id: string, authorizationCode: string, redirectUri: string): Observable<any> {
        return this.http.post(`/venue/${id}/shopifytokens`, {
            VenueId: id,
            AuthorizationCode: authorizationCode,
            RedirectURI: redirectUri
        });
    }

    shopifyTokensAtLocation(venueId: string, locationId: string, authorizationCode: string, redirectUri: string): Observable<any> {
        return this.http.post(`/venue/${venueId}/locations/${locationId}/shopifytokens`, {
            VenueId: venueId,
            LocationId: locationId,
            AuthorizationCode: authorizationCode,
            RedirectURI: redirectUri
        });
    }

    deleteShopifyTokensAtLocation(venueId: string, locationId: string): Observable<any> {
        return this.http.delete(`/venue/${venueId}/locations/${locationId}/shopifytokens`);
    }

    getPaymentInfo(id: string): Observable<VenueConfigPaymentInfoResponse> {
        return this.http.get(`/venue/${id}/config/paymentinfo`)
                   .pipe(map((response: any) => response));
    }

    putPaymentInfo(id: string, percentageFee: number, fixedFee: number): Observable<VenueConfigPaymentInfoResponse> {
        return this.http.put(`/venue/${id}/config/paymentinfo`, {
            VenueId: id,
            PercentageFee: percentageFee,
            FixedFee: fixedFee
        })
            .pipe(map((response: any) => response));
    }
    // End of Payments

    getLocations(id: string): Observable<VenueOrderLocationsResponse> {
        return this.http.get(`/venue/${id}/locations`)
                   .pipe(map(response => response));
    }

    setDomains(id: string, domains: string): Observable<any> {
        return this.http.post(`/venue/${id}/registerdomains`, {
            Domain: domains
        });
    }

    getMarketplace(id: string, locId: string): Observable<VenueMarketplaceSettings> {
        return this.http.get(`/venue/${id}/location/${locId}/marketplace`)
                   .pipe(map(response => response.Settings));
    }

    setMarketplace(id: string, locId: string, settings: VenueMarketplaceSettings): Observable<any> {
        return this.http.post(`/venue/${id}/location/${locId}/marketplace`, {
            Settings: settings
        });
    }

    clearMarketplace(id: string, locId: string) {
        return this.http.delete(`/venue/${id}/location/${locId}/marketplace`, {

        });
    }

    getDeliverect(id: string, locationId: string): Observable<VenueDeliverectSettings> {
        return this.http.get(`/venue/${id}/location/${locationId}/deliverect`)
                   .pipe(map(response => response));
    }

    saveDeliverect(id: string, locationId: string, siteId: string, locId: string, sendToLocation: string): Observable<any> {
        return this.http.post(`/venue/${id}/location/${locationId}/deliverect`, {
            DeliverectId: siteId,
            DeliverectLocationId: locId,
            SendToLocation: sendToLocation
        });
    }

    clearDeliverect(id: string, locationId: string) {
        return this.http.delete(`/venue/${id}/location/${locationId}/deliverect`, {});
    }

    getVenueSuppliers(id: string): Observable<VenueSupplierInfo[]> {
        return this.http
                   .get(`/venue/${id}/suppliers`)
                   .pipe(map((response: any) => response.Suppliers as VenueSupplierInfo[]));
    }

    getTyroConnect(venueId: string, location: string): Observable<VenueTyroSettings> {
        return this.http
                   .get(`/venue/${venueId}/location/${location}/tyro`)
                   .pipe(map((response: any) => response as VenueTyroSettings));
    }

    saveTyroConnect(venueId: string, location: string, tyroId: string, sendTo: string) {
        return this.http
                   .post(`/venue/${venueId}/location/${location}/tyro`, {
                       TyroId: tyroId,
                       SendToLocation: sendTo
                   });
    }

    clearTyroConnect(venueId: string, location: string) {
        return this.http
                   .delete(`/venue/${venueId}/location/${location}/tyro`);
    }

    connectToDeliverect(): Observable<any> {
        return this.http
                   .post(`/deliverect/tokens`, {});
    }

    deleteDeliverectToken(): Observable<any> {
        return this.http
                   .delete(`/deliverect/tokens`, {});
    }

    generateId(): string {
        return `${this.S4()}${this.S4()}${this.S4()}`;
    }

    private S4(): string {
        // tslint:disable-next-line:no-bitwise
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
}
