import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttp } from './baseHttp.service';
import { DateRange } from '../../shared/models';
import { RosterTemplatesResponse, location, employee, RostersResponse, TimesheetsResponse } from 'model';
import * as _ from 'underscore';
import { map } from 'rxjs/operators';

@Injectable()
export class TimeAttendanceService {
    constructor(private http: BaseHttp) { }

    getRosterTemplate(date: DateRange, locn: location[]): Observable<RosterTemplatesResponse> {
        let locationQuery = ``;

        if (locn.length > 0) {
            locationQuery = `&LocationId=${locn[0].LocationId}`;
        }

        return this.http.get(`/RosterTemplates?FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}`)
                   .pipe(map(response => response));
    }

    getRoster(date: DateRange, locn: location[], emp: employee[]): Observable<RostersResponse> {
        let locationQuery = ``;
        let employeeQuery = '';

        if (locn.length > 0) {
            locationQuery = `&LocationId=${locn[0].LocationId}`;
        }

        if (emp.length > 0) {
            employeeQuery = `&EmployeeId=${emp[0].EmployeeId}`;
        }

        return this.http.get(`/Rosters?FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}${employeeQuery}`)
                   .pipe(map(response => response));
    }

    getTimesheet(date: DateRange, locn: location[], emp: employee[]): Observable<TimesheetsResponse> {
        let locationQuery = ``;
        let employeeQuery = '';

        if (locn.length > 0) {
            locationQuery = `&LocationId=${locn[0].LocationId}`;
        }

        if (emp.length > 0) {
            employeeQuery = `&EmployeeId=${emp[0].EmployeeId}`;
        }

        return this.http.get(`/Timesheets?FromDate=${date.startDate.format('YYYY-MM-DD')}&ToDate=${date.endDate.format('YYYY-MM-DD')}${locationQuery}${employeeQuery}`)
                   .pipe(map(response => response));
    }
}
