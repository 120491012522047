import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute} from '@angular/router';

@Injectable()
export class CanActivateService implements CanActivate {

    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

       if (this.authService.isAuthenticated()) {
           const permissions = route.data.permissions;
            if (!!permissions && permissions.length) {
                return this.authService.hasPermission(route.data.permissions);
            }

            return true;
       }
       this.router.navigate(['membership/auth']);
       return false;
    }
}

