import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export const routes: Routes = [
    { path: '', redirectTo: 'membership', pathMatch: 'full' },
    { path: 'membership', loadChildren: () => import('app/membership/membership.module').then(m => m.MembershipModule) },
    { path: 'reports', loadChildren: () => import('app/reports/reports.module').then(m => m.ReportsModule) },
    { path: 'employees', loadChildren: () => import('app/employees/employees.module').then(m => m.EmployeesModule) },
    { path: 'loyalty', loadChildren: () => import('app/loyalty/loyalty.module').then(m => m.LoyaltyModule) },
    { path: 'admin', loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule) },
    { path: 'stripe', loadChildren: () => import('app/stripe/stripe.module').then(m => m.StripeModule) },
    { path: 'deputy', loadChildren: () => import('app/deputy/deputy.module').then(m => m.DeputyModule)},
    { path: 'involv', loadChildren: () => import('app/involv/involv.module').then(m => m.InvolvModule)}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }

