import { Component, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from './core/translations/translate.service';
import { AuthService } from './core/services';
const { version: appVersion } = require('../../package.json');

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  shouldShow: boolean;
  public appVersion: string;
  public copyDate: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private translateService: TranslateService) {

    this.appVersion = appVersion;

    const d: Date = new Date();
    this.copyDate = d.getFullYear().toString();

    this.shouldShow = false;

    this.router.events.subscribe((val: NavigationEnd) => {

      this.shouldShow = this.authService.isAuthenticated() && val.url !== '/membership/auth' && val.url !== '/' && val.url !== '/membership/selectvenue';

      if (this.shouldShow) {
        $('body').addClass('menu-showed');
      } else {
        $('body').removeClass('menu-showed');
      }

    });

    this.translateService.use('en');

  }
}
