import { FormGroup } from '@angular/forms';
import { Observable,  throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '../store';
import { environment } from './../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
  export class BaseHttp {

    options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'withCredentials': 'true',
          'Authorization': `X `
        })
      };

    private store: Store;


    constructor(private http: HttpClient, store: Store) {
        this.store = store;
    }

    errorHandl(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // Get client-side error
          errorMessage = error.error.Message;
        } else if (error.error && error.error.ResponseStatus) {
            errorMessage = error.error.ResponseStatus.Message;
        } else {
          // Get server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
      }

    put(url: string, body: any, options?: any): Observable<any> {
        return this.http
                   .put(environment.apiEndpoint + url, JSON.stringify(body), this.mergeOptions(options))
                   .pipe(catchError(this.errorHandl));
    }

    post(url: string, body: any, options?: any): Observable<any> {
        return this.http
                   .post(environment.apiEndpoint + url, JSON.stringify(body), this.mergeOptions(options))
                   .pipe(catchError(this.errorHandl));
    }

    get(url: string, options?: any): Observable<any> {
        return this.http
                   .get(environment.apiEndpoint + url, this.mergeOptions(options))
                   .pipe(catchError(this.errorHandl));
    }

    delete(url: string, options?: any): Observable<any> {
        return this.http
                   .delete(environment.apiEndpoint + url, this.mergeOptions(options))
                   .pipe(catchError(this.errorHandl));
    }

    private mergeOptions(options?: any): any {
        options = options || {};

        options.headers = this.options.headers || new HttpHeaders();
        const user = this.store.getUser();
        if (!!user) {
            options.headers = options.headers.set('withCredentials', 'true');
            if (!!user.Token) {
                options.headers = options.headers.set('Authorization', `Bearer ${user.Token}`);
            } else if (!!user.Auth) {
                options.headers = options.headers.set('Authorization', `Basic ${user.Auth}`);
            }
        }
        options.headers = options.headers.set('Content-Type', 'application/json');
        return options;
    }

    static errorParser(error: any, form?: FormGroup): { [key: string]: any } {

        if (error) {

            const errorResponse = {};

            if (error instanceof HttpErrorResponse) {
                if (error.status === 0) {
                    console.log('an error occured:', error.error);
                    errorResponse[error.status] = error.error;
                } else {
                    console.log(`Backend returned error code: ${error.status}: body was`, error.error);
                    errorResponse[error.status] = error.error;
                }
            } else {
                const doc = new DOMParser().parseFromString(error, 'text/html');

                if (error.status === 0) {
                    errorResponse[error.status] = 'please use https://... instead of http:// ...';
                } else {
                    errorResponse[error.status] = doc.body.textContent || '';
                }
            }
            if (form) {
                form.setErrors(errorResponse);
            }

            return error;
        }

        return null;
    }
}

